import { ReactNode } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import { getBlockUI } from "./slices/authSlice";
import RequireAuth from "./components/RequiredAuth";

import HomeView from "./views/HomeView";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import RegisterView from "./views/RegisterView";
import LoginView from "./views/LoginView";
import VerifyEmailSentView from "./views/VerifyEmailSentView";
import VerifyEmailView from "./views/VerifyEmailView";
import AppNavBar from "./components/AppNavBar";
import ResetPasswordView from "./views/ResetPasswordView";
import ResetPasswordEmailSentView from "./views/ResetPasswordEmailSentView";
import ResetPasswordConfirmView from "./views/ResetPasswordConfirmView";
import TermsOfServiceView from "./views/TermsOfServiceView";
import PrivacyPolicyView from "./views/PrivacyPolicyView";
import AboutView from "./views/AboutView";
import PricingView from "./views/PricingView";
import ContactView from "./views/ContactView";
import DashboardView from "./views/DashboardView";
import ProfileView from "./views/ProfileView";
import ChatsView from "./views//ChatsView";
import DownloadsView from "./views/DownloadsView";

type Props = {
  children: ReactNode;
};

function App(props: Props) {
  const { children } = props;
  const blocking = useSelector(getBlockUI);
  return <BlockUi blocking={blocking}>{children}</BlockUi>;
}

function WebLayout() {
  return (
    <div>
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
}

function AppLayout() {
  return (
    <RequireAuth>
      <div>
        <AppNavBar />
        <Outlet />
        <Footer />
      </div>
    </RequireAuth>
  );
}

export default function AppRoutes() {
  return (
    <Router>
      <App>
        <Routes>
          <Route path="/" element={<WebLayout />}>
            <Route path="*" element={<HomeView />} />
            <Route path="/" element={<HomeView />} />
            <Route path="/register" element={<RegisterView />} />
            <Route path="/login" element={<LoginView />} />
            <Route path="/pricing" element={<PricingView />} />
            <Route path="/contact" element={<ContactView />} />
            <Route
              path="/verify-email-sent"
              element={<VerifyEmailSentView />}
            />
            <Route path="/verify-email/:key" element={<VerifyEmailView />} />
            <Route path="reset-password" element={<ResetPasswordView />} />
            <Route
              path="reset-password-email-sent"
              element={<ResetPasswordEmailSentView />}
            />
            <Route
              path="reset-password/:uid/:token"
              element={<ResetPasswordConfirmView />}
            />
            <Route path="about" element={<AboutView />} />
            <Route path="terms-of-service" element={<TermsOfServiceView />} />
            <Route path="privacy-policy" element={<PrivacyPolicyView />} />
          </Route>
          {/* add new Route
          outsite Route with WebLayout */}
          <Route path="/app" element={<AppLayout />}>
            <Route path="/app" element={<DashboardView />} />
            <Route path="/app/chats" element={<ChatsView />} />
            <Route path="/app/profile" element={<ProfileView />} />
            <Route path="/app/downloads" element={<DownloadsView />} />
          </Route>
        </Routes>
      </App>
    </Router>
  );
}
