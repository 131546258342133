import { fetchLicenseKey, fetchUserInfo } from "../slices/authSlice";
import Title from "../components/TitleChanger";
import { IconMessages } from "@tabler/icons-react";
import { useEffect } from "react";
import { useAppDispatch } from "../store";
import { fetchMessages } from "../slices/completionSlice";
import Messages from "../components/Messages";

export default function ChatsView() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchMessages());
  }, [dispatch]);

  return (
    <div className="page">
      <Title pageTitle={"Chats | "} />
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1 className="display-6" style={{ fontWeight: "700" }}>
              <IconMessages style={{ paddingBottom: "5px" }} size={"36"} />
              Chats
            </h1>
            <hr />
            <Messages />
          </div>
        </div>
      </div>
    </div>
  );
}
