import { Link, useNavigate } from "react-router-dom";
import { logout } from "../slices/authSlice";
import { useAppDispatch } from "../store";
import {
  IconDownload,
  IconLayoutDashboard,
  IconLogout,
  IconMessages,
  IconUser,
} from "@tabler/icons-react";

export default function AppNavBar() {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className="container">
      {/* desktop version */}
      <header className="d-none d-md-flex flex-wrap justify-content-center pt-3 mb-4 px-4 px-lg-5 mx-2 mx-lg-5">
        <Link
          to="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <img
            alt="mljar logo"
            src={process.env.PUBLIC_URL + "/logo_blue.png"}
            className="header-logo"
          />
          <span className="header-font">Platform</span>
        </Link>

        <ul className="nav nav-pills my-auto nav-font-size">
          <li className="nav-item ">
            <Link to="/app" className="nav-link">
              <IconLayoutDashboard style={{ paddingBottom: "5px" }} />
              Dashboard
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/app/downloads" className="nav-link">
              <IconDownload style={{ paddingBottom: "5px" }} />
              Downloads
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/app/chats" className="nav-link">
              <IconMessages style={{ paddingBottom: "5px" }} />
              Chats
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/app/profile" className="nav-link">
              <IconUser style={{ paddingBottom: "5px" }} />
              Profile
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/app"
              className="nav-link active"
              onClick={() => dispatch(logout(navigate))}
            >
              <IconLogout style={{ paddingBottom: "4px", paddingTop: "2px" }} />
              Logout
            </Link>
          </li>
        </ul>
      </header>
      {/* mobile version */}
      <header className="block d-md-none navbar navbar-expand-md mb-3">
        <a
          href="/"
          className="d-flex align-items-center mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <img
            alt="mljar logo"
            src={process.env.PUBLIC_URL + "/logo_blue.png"}
            style={{
              height: "50px",
              padding: "0px",
              margin: "0px 10px 0px 0px",
            }}
          />
          <span className="fs-4">Licenses</span>
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav">
          <li className="nav-item">
            <a href="/app" className="nav-link pt-4">
              <IconLayoutDashboard style={{ paddingBottom: "5px" }} />
              Dashboard
            </a>
          </li>
          <li className="nav-item">
            <a href="/app/downloads" className="nav-link">
              <IconDownload style={{ paddingBottom: "5px" }} />
              Downloads
            </a>
          </li>
          <li className="nav-item">
            <a href="/app/chats" className="nav-link">
              <IconMessages style={{ paddingBottom: "5px" }} />
              Chats
            </a>
          </li>
          <li className="nav-item">
            <a href="/app/profile" className="nav-link">
              <IconUser style={{ paddingBottom: "5px" }} />
              Profile
            </a>
          </li>
          <li className="nav-item">
            <a
              href="/app"
              className="nav-link active"
              onClick={() => dispatch(logout(navigate))}
              style={{color: "#0D6EFD"}}
            >
              <IconLogout style={{ paddingBottom: "4px", paddingTop: "2px",  color: "#0D6EFD"}} />
              Logout
            </a>
          </li>
          </ul>
        </div>
      </header>
    </div>
  );
}
