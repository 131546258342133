import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Title from "../components/TitleChanger";
import { isAuthenticated } from "../slices/authSlice";

export default function HomeView() {
  const navigate = useNavigate();
  const isLogged = useSelector(isAuthenticated);
  return (
    <div>
      <Title pageTitle={""} />
      <div className="container">
        <div className="col-md-10 offset-md-1">
          <div className="row py-4">
            <div className="col my-auto">
              <h1 className="title" style={{ fontWeight: "800" }}>
                Data Science and Machine Learning Platform
              </h1>
              <p
                className="subtitle"
                style={{ fontWeight: "300", color: "rgb(30 41 59)" }}
              >
                MLJAR Studio is Python editor created for Data Science and
                Machine Learning tasks
              </p>
              <>
                {!isLogged && (
                  <>
                    <div className="text-center text-md-start">
                      <button
                        className="btn btn-primary px-4 py-2"
                        onClick={() => navigate("/login")}
                      >
                        Login
                      </button>
                      <button
                        className="btn btn-outline-dark mx-2 px-4 py-2"
                        onClick={() => navigate("/register")}
                      >
                        Sign Up
                      </button>
                    </div>
                  </>
                )}
                {isLogged && (
                  <div className="text-center text-md-start">
                    <button
                      className="btn btn-primary px-4 py-2"
                      onClick={() => navigate("/app")}
                    >
                      Open Dashboard
                    </button>
                  </div>
                )}
              </>
            </div>
            <div className="col my-auto d-none d-md-block text-center">
              <img
                alt="MLJAR Studio forever"
                src="/icon.svg"
                style={{ width: "80%" }}
              />
            </div>
          </div>
          <div className="row pt-3">
            <h2 className="" style={{ fontWeight: "700" }}>
              Features
            </h2>{" "}
          </div>
          <div className="d-flex flex-column flex-md-row pt-3 mb-3">
            <div className="homeview-tiles">
              <div className="card mb-3" style={{ height: "80%" }}>
                <div className="card-body">
                  <h5 className="tiles-title">Desktop app with Python</h5>
                  <p className="tiles-content">
                    We managed Python environment for you in MLJAR desktop app.
                  </p>
                </div>
              </div>
            </div>
            <div className="homeview-tiles">
              <div className="card mb-3" style={{ height: "80%" }}>
                <div className="card-body">
                  <h5 className="tiles-title">Piece of Code</h5>
                  <p className="tiles-content">
                    Get access to interactive code recipes tailored for Machine
                    Learning.
                  </p>
                </div>
              </div>
            </div>
            <div className="homeview-tiles">
              <div className="card mb-3" style={{ height: "80%" }}>
                <div className="card-body">
                  <h5 className="tiles-title">AI Assistant</h5>
                  <p className="tiles-content">
                    Ask AI Assistant to help you with your data analysis code.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center text-size">
            Read more about MLJAR Studio on{" "}
            <a
              href="https://mljar.com/docs/"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              documentation website
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  );
}
