import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState, TypedDispatch } from "../store";

export type CompletionCountType = {
  limit: number;
  count: number;
};

export type MessageType = {
  id: number;
  prompt: string;
  response: string;
  date: string;
};

const initialState = {
  completionCount: { limit: 0, count: 0 } as CompletionCountType,
  messages: [] as MessageType[],
};

const completionSlice = createSlice({
  name: "completion",
  initialState,
  reducers: {
    setCompletionCount(state, action: PayloadAction<CompletionCountType>) {
      state.completionCount = action.payload;
    },
    setMessages(state, action: PayloadAction<MessageType[]>) {
      state.messages = action.payload;
    },
  },
});

export default completionSlice.reducer;

export const { setCompletionCount, setMessages } = completionSlice.actions;

export const getCompletionCount = (state: RootState) =>
  state.completion.completionCount;

export const getMessage = (state: RootState) =>
  state.completion.messages;

export const fetchCompletionCounter = () => async (dispach: TypedDispatch) => {
  try {
    const url = "api/completioncount/";
    const { data } = await axios.get(url);
    dispach(setCompletionCount(data));
  } catch (error) {
    // const err = error as AxiosError;
  }
};

export const fetchMessages = () => async (dispach: TypedDispatch) => {
  try {
    const url = "api/messages/";
    const { data } = await axios.get(url);
    dispach(setMessages(data));
    // console.log(data);
  } catch (error) {
    // const err = error as AxiosError;
  }
};
