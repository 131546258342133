import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteAccount, UserType } from "../slices/authSlice";
import { useAppDispatch } from "../store";
import { IconArrowDown, IconArrowUp, IconTrash } from "@tabler/icons-react";

type Props = {
  user: UserType;
};

export default function DeleteAccount({ user }: Props) {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [deleteExpand, setDeleteExpand] = useState(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <h4
          style={{
            display: "flex",
            alignItems: "center",
            lineHeight: "1",
            fontSize: "14px",
            margin: 0,
          }}
        >
          <IconTrash
            style={{ paddingBottom: "3px", width: "16px", height: "16px" }}
          />
          Delete Account
        </h4>
        <button
          onClick={() => {
            deleteExpand ? setDeleteExpand(false) : setDeleteExpand(true);
          }}
          style={{
            backgroundColor: "transparent",
            border: "2px solid #212529",
            borderRadius: "8px",
            padding: "3px 6px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {deleteExpand ? (
            <IconArrowUp
              style={{ width: "16px", height: "16px", color: "#212529" }}
            />
          ) : (
            <IconArrowDown
              style={{ width: "16px", height: "16px", color: "#212529" }}
            />
          )}
        </button>
      </div>
      {deleteExpand && (
        <>
          <p className="mt-2">
            Please write your email <b>{user.email}</b> in the text input below
            to confirm account removal.
          </p>
          <b>
            All your data will be removed from our system and you will be logged
            out.
          </b>
          <div className="my-2">
            <input
              type="text"
              className="form-control"
              placeholder="Write your email to confirm removal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button
            className="btn btn-danger"
            onClick={(e) => {
              dispatch(deleteAccount(navigate));
            }}
            disabled={email !== user.email}
          >
            Permanently Delete
          </button>
        </>
      )}
    </div>
  );
}
