import { IconCheck, IconCurrencyDollar } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

export default function PricingView() {

  const navigate = useNavigate();
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1 className="display-6" style={{ fontWeight: "700" }}>
              <IconCurrencyDollar
                style={{ paddingBottom: "5px" }}
                size={"40"}
              />
              Pricing
            </h1>
            <hr className="mb-5" />
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-3 mb-5 text-center mx-auto pricing">
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Free</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title my-5">
                  $0<small className="text-body-secondary fw-light">/mo</small>
                </h1>
                <ul
                  className="list-unstyled mb-5 text-start fs-6"
                  style={{ marginLeft: 10 }}
                >
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    Desktop app with managed Python
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    Access to Piece of Code
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    20 prompts to AI Assistant
                  </li>
                </ul>
                <button
                  type="button"
                  className="btn btn-lg btn-outline-primary"
                  style={{ width: "80%" }}
                  onClick={() => navigate("/app")}
                >
                  Get started
                </button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm border-primary">
              <div className="card-header py-3 text-bg-primary border-primary">
                <h4 className="my-0 fw-normal">Pro</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title my-5">
                  $20<small className="text-body-secondary fw-light">/mo</small>
                </h1>
                <ul
                  className="list-unstyled mb-5 text-start fs-6"
                  style={{ marginLeft: 10 }}
                >
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck
                      className="pricing-icon"
                      // style={{ color: "#0D6EFD", backgroundColor: "white", height: "26", width: "26", marginBottom: "2px"}}
                    />
                    Desktop app with managed Python
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck
                      className="pricing-icon"
                      // style={{ color: "#0D6EFD", backgroundColor: "white", height: "26", width: "26", marginBottom: "2px" }}
                    />
                    Access to Piece of Code
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck
                      className="pricing-icon"
                      // style={{ color: "#0D6EFD", backgroundColor: "white", height: "26", width: "26", marginBottom: "2px" }}
                    />
                    250 prompts to AI Assistant
                  </li>
                </ul>
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  style={{ width: "80%" }}
                  onClick={() => navigate("/app")}
                >
                  Get started
                </button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Business</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title my-5">
                  $40<small className="text-body-secondary fw-light">/mo</small>
                </h1>
                <ul
                  className="list-unstyled mb-5 text-start fs-6"
                  style={{ marginLeft: 10 }}
                >
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    Desktop app with managed Python
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    Access to Piece of Code
                  </li>
                  <li className="d-flex align-items-center mb-2 my-auto">
                    <IconCheck className="pricing-icon" />
                    500 prompts to AI Assistant
                  </li>
                </ul>
                <button
                  type="button"
                  className="btn btn-lg btn-outline-primary"
                  style={{ width: "80%" }}
                  onClick={() => navigate("/app")}
                >
                  Get started
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="faq-title mb-3">Frequently Asked Questions</h2>
          <ul className="list-unstyled text-start faq mx-auto">
            <li className="faq-questions" style={{ fontWeight: 600 }}>
              What payment methods are available?
              <p
                className="faq-answers"
                style={{ fontWeight: 300, marginLeft: "20px" }}
              >
                You can pay securely with credit or debit card and Paypal.
              </p>
            </li>
            <li className="faq-questions" style={{ fontWeight: 600 }}>
              Why do I need to pay VAT?
              <p
                className="faq-answers"
                style={{ fontWeight: 300, marginLeft: "20px" }}
              >
                VAT is a required sales tax in some countries. Fill in your
                company details during checkout (or after getting the invoice)
                and the VAT will either be deducted or you can use the invoice
                to remit the VAT from your tax authority.
              </p>
            </li>
            {/* <li className="faq-questions" style={{ fontWeight: 600 }}>
              Can I share my license with others?
              <p
                className="faq-answers"
                style={{ fontWeight: 300, marginLeft: "20px" }}
              >
                You can activate your MLJAR Studio license on up to 3 computers
                but it is exclusive to one user only. If you need a license for
                multiple users please contact us.
              </p>
            </li> */}
            <li className="faq-questions" style={{ fontWeight: 600 }}>
              What is your refund policy?
              <p
                className="faq-answers"
                style={{ fontWeight: 300, marginLeft: "20px" }}
              >
                We want you to be delighted with your purchase. If you aren't,
                notify us within 30 days of purchase and we will refund your
                full payment. 
              </p>
            </li>

            <li className="faq-questions" style={{ fontWeight: 600 }}>
              Who owns the code created in MLJAR Studio?
              <p
                className="faq-answers"
                style={{ fontWeight: 300, marginLeft: "20px" }}
              >
                You! Regardless of whether you use the free, pro or business
                version of MLJAR Studio, all created code is yours and free to be
                used however you like, including commercially.
              </p>
            </li>

            <li className="faq-questions" style={{ fontWeight: 600 }}>
              More questions?
              <p
                className="faq-answers"
                style={{ fontWeight: 300, marginLeft: "20px" }}
              >
                Please contact us for any further questions by email{" "}
                <b>contact@mljar.com</b>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
