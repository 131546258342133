import { useSelector } from "react-redux";
import {
  downloadStudio,
  fetchReleases,
  getDownloadLinks,
  getReleases,
} from "../slices/authSlice";
import { useAppDispatch } from "../store";
import Title from "../components/TitleChanger";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IconDownload } from "@tabler/icons-react";

export default function DownloadsView() {
  let navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const downloadLinks = useSelector(getDownloadLinks);
  const dispatch = useAppDispatch();
  const releases = useSelector(getReleases);

  useEffect(() => {
    dispatch(fetchReleases());
  }, [dispatch]);

  const elements = releases
    .filter((r) => (showAll ? true : r.latest))
    .map((r) => {
      return (
        <tr key={`${r.name}`} style={{ verticalAlign: "middle" }}>
          <td style={{ fontFamily: "monospace" }}>{r.name}</td>
          <td>{r.operating_system}</td>
          <td>{r.version}</td>
          <td>{r.file_size}</td>
          {/* <td>{r.checksum}</td> */}
          <td>
            <button
              className="btn btn-primary"
              onClick={(e) => {
                dispatch(downloadStudio(navigate, r.id));
              }}
              // disabled={true}
            >
              Get it
            </button>
            {downloadLinks[r.id] !== undefined &&
              downloadLinks[r.id] !== "" && (
                <a className="btn btn-link" href={downloadLinks[r.id]}>
                  Download
                </a>
              )}
            {(downloadLinks[r.id] === undefined ||
              downloadLinks[r.id] === null ||
              downloadLinks[r.id] === "") && (
              <div style={{ display: "inline", paddingLeft: "120px" }}></div>
            )}
          </td>
        </tr>
      );
    });

  return (
    <div className="page">
      <Title pageTitle={"Downloads | "} />
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1 className="display-6" style={{ fontWeight: "700" }}>
              <IconDownload style={{ paddingBottom: "5px" }} size={"36"} />
              Downloads
            </h1>
            <hr />
            <p>
              You can find here the latest releases of MLJAR Studio. Please
              download installer for your operating system. If you have any
              problems with installation or need help please contact us at
              contact@mljar.com.
            </p>
          </div>
        </div>
        <div
          className="col-md-10 offset-md-1 pt-4"
          style={{ height: "400px", overflow: "auto" }}
        >
          <div className="table-responsive">
            <table className="table">
              <thead className=" ">
                <tr className=" ">
                  <th scope="col">Release name</th>
                  <th scope="col">Operating system</th>
                  <th scope="col">Version</th>
                  <th scope="col">File size</th>
                  {/* <th scope="col">SHA-256</th> */}
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>{elements}</tbody>
            </table>
          </div>
        </div>
        <div className="col-md-10 offset-md-1">
          <button
            className="btn btn-sm btn-link"
            onClick={() => setShowAll(!showAll)}
          >
            {!showAll ? "Show all versions" : "Show latest version"}
          </button>
        </div>
      </div>
    </div>
  );
}
