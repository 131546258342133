import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getToken } from "../slices/authSlice";

export default function NavBar() {
  let token = useSelector(getToken);

  return (
    <div className="container">
      {/* desktop version */}
      <header className="d-none d-md-flex flex-wrap justify-content-center pt-3 mb-4 px-4 px-lg-5 mx-2 mx-lg-5">
        <Link
          to="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <img
            alt="mljar logo"
            src={process.env.PUBLIC_URL + "/logo_blue.png"}
            style={{
              height: "50px",
              padding: "0px",
              margin: "0px 10px 0px 0px",
            }}
          />
          <span className="fs-4">Platform</span>
        </Link>

        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link to="/pricing" className="nav-link">
              Pricing
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="https://mljar.com/docs/"
              className="nav-link"
              target="_blank"
            >
              Docs
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/contact/" className="nav-link">
              Contact
            </Link>
          </li>
          {!token && (
            <li className="nav-item">
              <b className="nav-link px-1" style={{ color: "gray" }}>
                |
              </b>
            </li>
          )}
          {!token && (
            <>
              <li className="nav-item">
                <Link to="/register" className="nav-link">
                  Sign Up
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/login" className="nav-link active">
                  Login
                </Link>
              </li>
            </>
          )}
          {token && (
            <li className="nav-item">
              <Link to="/app" className="nav-link active">
                Open Dashboard
              </Link>
            </li>
          )}
        </ul>
      </header>
      {/* mobile version */}
      <header className="block d-md-none navbar navbar-expand-md mb-3">
        <a
          href="/"
          className="d-flex align-items-center mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <img
            alt="mljar logo"
            src={process.env.PUBLIC_URL + "/logo_blue.png"}
            style={{
              height: "50px",
              padding: "0px",
              margin: "0px 10px 0px 0px",
            }}
          />
          <span className="fs-4">Licenses</span>
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                href="/pricing"
                className="nav-link pt-4"
                style={{ padding: "5px 18px" }}
              >
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://mljar.com/docs/"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                style={{ padding: "5px 18px" }}
              >
                Docs
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/contact/"
                className="nav-link"
                style={{ padding: "5px 18px" }}
              >
                Contact
              </a>
            </li>
            <li>
              <hr style={{ width: "50%" }} />
            </li>

            {!token && (
              <>
                <div className="d-flex gap-3">
                  <li className="nav-item">
                    <a
                      href="/register"
                      className="nav-link"
                      style={{
                        padding: "5px 18px",
                        borderRadius: "8px",
                        border: "2px solid",
                        marginLeft: "2px",
                      }}
                    >
                      Sign Up
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/login"
                      className="nav-link active"
                      style={{
                        backgroundColor: "#0d6efd",
                        padding: "6px 18px",
                        borderRadius: "8px",
                        color: "white",
                        border: "2px solid",
                      }}
                    >
                      Login
                    </a>
                  </li>
                </div>
              </>
            )}
            {token && (
              <li className="nav-item">
                <a
                  href="/app"
                  className="nav-link active"
                  style={{
                    backgroundColor: "#0d6efd",
                    padding: "6px 18px",
                    borderRadius: "8px",
                    color: "white",
                    border: "2px solid",
                    width: "50%",
                    textAlign: "center"
                  }}
                >
                  Open Dashboard
                </a>
              </li>
            )}
          </ul>
        </div>
      </header>
    </div>
  );
}
