import { useEffect } from "react";
import { useAppDispatch } from "../store";
import {
  fetchCompletionCounter,
  getCompletionCount,
} from "../slices/completionSlice";
import { useSelector } from "react-redux";
import { IconMessages } from "@tabler/icons-react";

export default function CompletionCount() {
  const dispatch = useAppDispatch();
  const completionCount = useSelector(getCompletionCount);

  useEffect(() => {
    dispatch(fetchCompletionCounter());
  }, [dispatch]);

  return (
    <div>
      <h4>
        <IconMessages style={{ paddingBottom: "3px", marginRight: "2px" }} />
        Completions counter
      </h4>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%", textAlign: "left" }}>
          <p>
            Available completions: {completionCount.limit - completionCount.count}
          </p>
          <p>
            Usage: {completionCount.count}/{completionCount.limit}
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center", width: "90%" }}>
          <span>0</span>
          <progress
            style={{ width: "100%", margin: "0 10px" }}
            id="progress-bar"
            value={completionCount.count}
            max={completionCount.limit}
          ></progress>
          <span>{completionCount.limit}</span>
        </div>
      </div>
    </div>
  );
}
