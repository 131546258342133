import Title from "../components/TitleChanger";

export default function VerifyEmailSentView() {
    return (
      <div>
      <Title pageTitle={ 'Verify email | ' } />
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h1>Verify Email Sent</h1>
  
              <p>
                Please check your email. We send you a verification link. Please
                double-check your email inbox. The verification email might be in the
                spam folder.
              </p>
  
              <p>
                In case of any problems, please contact us by email{" "}
                <b>contact@mljar.com</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }