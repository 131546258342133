import {
  IconAddressBook,
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandX,
} from "@tabler/icons-react";
import Title from "../components/TitleChanger";

export default function ContactView() {
  return (
    <div>
      <Title pageTitle={"Contact | "} />
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1 className="display-6" style={{ fontWeight: "700" }}>
              <IconAddressBook style={{ paddingBottom: "5px" }} size={"40"} />
              Contact
            </h1>
            <hr className="mb-5" />
            <p className="fs-5">
              Please don't hesitate to reach out if you find our offer,
              products, or collaboration opportunities interesting. We would
              love to hear about any use cases for our tools that you might
              have. Our team is eager to listen to your story and assist you
              with your project!
            </p>
            <p className="fs-5 mb-0">
              Our contact email: <b>contact@mljar.com</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
