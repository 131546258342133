import { combineReducers } from "redux";
import { History } from "history";

import authReducer from "./slices/authSlice";
import completionReducer from "./slices/completionSlice";

export default function createRootReducer(history: History) {
  return combineReducers({
    auth: authReducer,
    completion: completionReducer,
  });
}