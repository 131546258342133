import { IconExternalLink } from "@tabler/icons-react";

export default function Footer() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1 mt-5">
          <footer className="py-3 my-4">
            <hr />
            <ul className="nav justify-content-center pb-2">
              <li className="nav-item">
                <a href="/pricing/" className="nav-link px-2 text-muted">
                  Pricing
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://mljar.com/docs/"
                  className="nav-link px-2 text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  Docs
                  <IconExternalLink style={{ paddingBottom: "5px" }} />
                </a>
              </li>
              <li className="nav-item">
                <a href="/contact/" className="nav-link px-2 text-muted">
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a href="/privacy-policy/" className="nav-link px-2 text-muted">
                  Privacy Policy
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/terms-of-service/"
                  className="nav-link px-2 text-muted"
                >
                  Terms of Service
                </a>
              </li>
            </ul>
            <p className="text-center text-muted">
              <a
                href="https://mljar.com/"
                target="_blank"
                className="nav-link px-2 text-muted"
                rel="noreferrer"
              >
                © MLJAR Sp. z o.o.
                <IconExternalLink style={{ paddingBottom: "5px" }} />
              </a>
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}
