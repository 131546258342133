import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../store";
import { fetchMessages, getMessage } from "../slices/completionSlice";
import { useSelector } from "react-redux";
import MarkdownIt from "markdown-it";
import markdownItHighlightjs from "markdown-it-highlightjs";
import "highlight.js/styles/vs.css";

export default function MessagesByDate() {
  const dispatch = useAppDispatch();
  const messages = useSelector(getMessage);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchMessages());
  }, [dispatch]);

  // date formatting
  const formatDate = (isoDate: string) => {
    const date = new Date(isoDate);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const md = new MarkdownIt().use(markdownItHighlightjs);

  const selectedMessage =
    selectedDate && messages.find((message) => message.date === selectedDate);

  return (
    <>
      {messages.length === 0 ? (
        <p className="text-center mb-0">
          No messages are available yet. Please use the AI Assistant in MLJAR
          Studio to see your messages here.
        </p>
      ) : (
        <>
          <div className="d-flex h-100">
            {/* list of dates */}
            <div
              className="flex-shrink-0 border-end p-3 overflow-auto"
              style={{ width: "25%" }}
            >
              <h4>Dates</h4>
              <ul className="list-unstyled p-0">
                {messages
                  .slice()
                  .sort(
                    (a, b) =>
                      new Date(b.date).getTime() - new Date(a.date).getTime()
                  )
                  .map((message) => (
                    <li
                      key={message.id}
                      className="p-2 mb-2 rounded"
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          selectedDate === message.date
                            ? "#f5f5f5"
                            : "transparent",
                            border: selectedDate === message.date ? "1px solid #E0E0E0" : "none",
                      }}
                      onClick={() => setSelectedDate(message.date)}
                    >
                      {formatDate(message.date)}
                    </li>
                  ))}
              </ul>
            </div>

            {/* message for specific date */}
            <div className="flex-fill p-3">
              {selectedMessage ? (
                <>
                  <h4 className="mb-4">Prompt:</h4>
                  <div
                    className="mb-4"
                    dangerouslySetInnerHTML={{
                      __html: md.render(selectedMessage.prompt),
                    }}
                  ></div>
                  <h4 className="mb-4">Response:</h4>
                  <div
                    className="mb-4"
                    dangerouslySetInnerHTML={{
                      __html: md.render(selectedMessage.response),
                    }}
                  ></div>
                </>
              ) : (
                <p>Select a date to see the message.</p>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
