import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  fetchLicenseKey,
  fetchUserInfo,
  getLicenseKey,
  getUserInfo,
} from "../slices/authSlice";
import { useAppDispatch } from "../store";
import Title from "../components/TitleChanger";
import {
  IconBook2,
  IconDownload,
  IconLayoutDashboard,
  IconLicense,
  IconLifebuoy,
} from "@tabler/icons-react";

export default function DashboardView() {
  const dispatch = useAppDispatch();
  const licenseKey = useSelector(getLicenseKey);
  const user = useSelector(getUserInfo);

  useEffect(() => {
    dispatch(fetchUserInfo());
    dispatch(fetchLicenseKey());
  }, [dispatch]);

  return (
    <div>
      <Title pageTitle={"Dashboard | "} />
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1 className="display-6" style={{ fontWeight: "700" }}>
              <IconLayoutDashboard
                style={{ paddingBottom: "5px" }}
                size={"36"}
              />
              Dashboard
            </h1>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2 className="">Hello {user.username} 👋</h2>
            <p>
              MLJAR Studio is a new programming suite for everyone! When we were
              learning Python, we faced many issues ourselves. We made MLJAR
              Studio to solve coding hurdles. We designed interactive code
              recipes with a hand crafted user interface to help you write
              Python effortlessly. What is more, all the required packages are
              imported and installed automatically, so you can{" "}
              <u>focus on thinking</u>.
            </p>
            <a href="/app/downloads" className="btn btn-primary">
              <IconDownload style={{ paddingBottom: "5px" }} /> Downloads
            </a>
            {/* {licenseKey === "" && (
              <a
                href={`https://mljar.com/pricing/?customerData=${user.email}`}
                target="_blank"
                className="btn btn-outline-dark mx-2"
                rel="noreferrer"
              >
                <IconLicense style={{ paddingBottom: "5px" }} /> Buy license
              </a>
            )}
            {licenseKey !== "" && (
              <a href={"/app/license"} className="btn btn-outline-dark mx-2">
                <IconLicense style={{ paddingBottom: "5px" }} /> My license
              </a>
            )} */}
            
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-md-5 offset-md-1">
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title">
                  <IconBook2 style={{ paddingBottom: "5px" }} />
                  Documentation
                </h5>
                <p className="card-text">
                  Check documentation about each code recipes available in MLJAR
                  Studio.
                </p>

                <a
                  href="https://mljar.com/docs/"
                  target="_blank"
                  className="btn btn-primary"
                  rel="noreferrer"
                >
                  Read Docs
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title">
                  <IconLifebuoy style={{ paddingBottom: "5px" }} />
                  Support
                </h5>
                <p className="card-text">
                  Do you need help or have any questions. Please contact us by
                  email at contact@mljar.com
                </p>

                <a
                  href="https://mljar.com/contact/"
                  target="_blank"
                  className="btn btn-primary"
                  rel="noreferrer"
                >
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
