import {
  UserType,
  checkSubscription,
  getPlan,
  cancelSubscription,
} from "../slices/authSlice";
import { IconCurrencyDollar } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import { useEffect, useState } from "react";
import { fetchCompletionCounter } from "../slices/completionSlice";

type Props = {
  user: UserType;
};

declare global {
  interface Window {
    Paddle: any;
  }
}
const MLJAR_STUDIO_PRO_ID = 900861;
const MLJAR_STUDIO_BUSINESS_ID = 900862;
const MLJAR_VENDOR_ID = 124786;

export default function Subscription({ user }: Props) {
  const dispatch = useAppDispatch();
  const Paddle = window.Paddle;
  const plan = useSelector(getPlan);
  const [email, setEmail] = useState("");
  const [activeButton, setActiveButton] = useState(false);
  const handleButtonClick = (button: boolean) => {
    setActiveButton(button);
  };

  useEffect(() => {
    dispatch(checkSubscription());
  }, []);

  Paddle.Setup({
    vendor: MLJAR_VENDOR_ID,
    eventCallback: function (data: any) {
      if (data.event === "Checkout.Close") {
        dispatch(checkSubscription());
        dispatch(fetchCompletionCounter());
      }
    },
  });

  return (
    <div>
      <h4>
        <IconCurrencyDollar style={{ paddingBottom: "3px" }} />
        Subscription
      </h4>

      {user.profile.plan === "unknown" && <p>Loading ...</p>}
      {user.profile.plan !== "unknown" && (
        <>
          <p className="mb-3">
            You are using <b>{plan}</b> plan.
          </p>

          {plan !== "free" && (
            <div>
              {activeButton && (
                <>
                  <p className="mb-0">
                    Please write your email <b>{user.email}</b> in the text
                    input below to confirm subscription cancelation.
                  </p>
                  <input
                    type="text"
                    className="form-control my-2 "
                    placeholder="Write your email to confirm the cancelation"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    className="btn btn-danger mb-3"
                    onClick={(e) => {
                      dispatch(cancelSubscription());
                      window.location.reload();
                    }}
                    disabled={email !== user.email}
                  >
                    Cancel subscription
                  </button>
                  <button
                    className={`btn btn-primary ms-3 mb-3`}
                    onClick={(e) => {
                      handleButtonClick(false);
                    }}
                  >
                    Hide
                  </button>
                </>
              )}

              <button
                className={`btn btn-danger mb-3 ${
                  activeButton ? `d-none` : ``
                }`}
                onClick={(e) => {
                  handleButtonClick(true);
                }}
              >
                Cancel subscription
              </button>
            </div>
          )}

          {plan !== "free" && (
            <p className="">
              If you would like to upgrade or downgrade subscription please
              contact us be email. If you have any problem or question with
              subscription please contact us. Our email is{" "}
              <b>contact@mljar.com</b>.
            </p>
          )}

          {plan === "free" && (
            <div>
              <h5>Paid plans</h5>
              <p>
                Please select from plans below. If you have VAT number please
                provide it during checkout. Local taxes might be applied.
              </p>
              <button
                className="btn btn-success"
                onClick={(e) => {
                  Paddle.Checkout.open({
                    product: MLJAR_STUDIO_PRO_ID,
                    email: user.email,
                    customData: {
                      userId: user.pk,
                    },
                  });
                }}
              >
                Pro 20$/mo
              </button>
              <button
                className="btn btn-success mx-2"
                onClick={(e) => {
                  Paddle.Checkout.open({
                    product: MLJAR_STUDIO_BUSINESS_ID,
                    email: user.email,
                    customData: {
                      userId: user.pk,
                    },
                  });
                }}
              >
                Business 40$/mo
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
