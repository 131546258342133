import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../slices/authSlice";
import { useAppDispatch } from "../store";
import Title from "../components/TitleChanger";

export default function ResetPasswordView() {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");

  return (
    <div>
    <Title pageTitle={ 'Reset confirm | ' } />
      <div
        className="container"
        style={{ paddingTop: "70px", paddingBottom: "70px" }}
      >
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(resetPassword(email, navigate));
              }}
            >
              <h1 className="h3 mb-3 fw-normal">Reset Password</h1>
              <div className="my-2">
                <label>Email address</label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <button
                className="w-100 btn btn-lg btn-primary my-2"
                disabled={!email.includes("@") || email.endsWith("@")}
              >
                Reset password
              </button>
            </form>

            <p className="my-2">
              <a href="/login">Login</a>
              <a className="m-3" href="/register">
                Register
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
